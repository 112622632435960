*,
::after,
::before {
    box-sizing: border-box;
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&family=Montserrat&display=swap');

html {
    font-size: 100%;
} /*16px*/

:root {
    /* colors */
    /* --primary: #f28c0c; */
    --primary-100: hsl(33, 91%, 90%);
    --primary-200: hsl(33, 91%, 80%);
    --primary-300: hsl(33, 91%, 70%);
    --primary-400: hsl(33, 91%, 60%);
    --primary-500: hsl(33, 91%, 50%);
    --primary-600: hsl(33, 91%, 40%);
    --primary-700: hsl(33, 91%, 30%);
    --primary-800: hsl(33, 91%, 20%);
    --primary-900: hsl(33, 91%, 10%);
    /* #645CFF */
    /* --primary-100: #e2e0ff;
    --primary-200: #c1beff;
    --primary-300: #a29dff;
    --primary-400: #837dff;
    --primary-500: #645cff;
    --primary-600: #504acc;
    --primary-700: #3c3799;
    --primary-800: #282566;
    --primary-900: #141233; */

    /* grey */
    --grey-50: #f8fafc;
    --grey-100: #f1f5f9;
    --grey-200: #e2e8f0;
    --grey-300: #cbd5e1;
    --grey-400: #94a3b8;
    --grey-500: #64748b;
    --grey-600: #475569;
    --grey-700: #334155;
    --grey-800: #1e293b;
    --grey-900: #0f172a;
    /* rest of the colors */
    --black: #222;
    --white: #fff;
    --red-light: #f8d7da;
    --red-dark: #842029;
    --green-light: #d1e7dd;
    --green-dark: #0f5132;

    /* fonts  */
    --headingFont: 'Roboto', sans-serif;
    --bodyFont: 'Nunito', sans-serif;
    --smallText: 0.7em;
    --small-text: 0.875rem;
    /* rest of the vars */
    --backgroundColor: var(--grey-50);
    --textColor: var(--grey-900);
    --borderRadius: 0.25rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --max-width: 1120px;
    --fixed-width: 500px;
    --fluid-width: 90vw;

    /* box shadow*/
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.submit-btn {
    transform: translateX(50%);
}
@media (max-width: 768px) {
    .submit-btn {
        transform: translateX(0%);
    }
}

nav img {
    width: auto;
    height: 50px !important;
}

form img {
    width: auto;
    height: 50px !important;
}

body {
    background: var(--backgroundColor);
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.75;
    color: var(--textColor);
}

p {
    margin-bottom: 1.5rem;
    max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    margin-bottom: 1.38rem;
    font-family: var(--headingFont);
    font-weight: 400;
    line-height: 1.3;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text-small {
    font-size: var(--smallText);
}

a {
    text-decoration: none;
}
ul {
    list-style-type: none;
    padding: 0;
}

.img {
    width: 100%;
    display: block;
    object-fit: cover;
}
/* buttons */

.btn {
    cursor: pointer;
    color: var(--white);
    background: var(--primary-500);
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-1);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
}
.btn:hover {
    background: var(--primary-700);
    box-shadow: var(--shadow-3);
}
.btn-hipster {
    color: var(--primary-500);
    background: var(--primary-200);
}
.btn-hipster:hover {
    color: var(--primary-200);
    background: var(--primary-700);
}
.btn-block {
    width: 100%;
}
.hero-btn {
    font-size: 1.25rem;
    padding: 0.5rem 1.25rem;
}
/* alerts */
.alert {
    padding: 0.375rem 0.75rem;
    margin: 0 auto;
    border-color: transparent;
    border-radius: var(--borderRadius);
    width: var(--fluid-width);
    max-width: var(--fixed-width);
    text-align: center;
    text-transform: capitalize;
}

.alert-danger {
    color: var(--red-dark);
    background: var(--red-light);
}
.alert-success {
    color: var(--green-dark);
    background: var(--green-light);
}
/* form */

.form {
    width: var(--fluid-width);
    max-width: var(--fixed-width);
    background: var(--white);
    border-radius: var(--borderRadius);
    box-shadow: var(--shadow-2);
    padding: 2rem 2.5rem;
    margin: 3rem auto;
}
.form-label {
    display: block;
    font-size: var(--smallText);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--borderRadius);
    background: var(--backgroundColor);
    border: 1px solid var(--grey-200);
}

.form-row {
    margin-bottom: 1rem;
}

.form-textarea {
    height: 7rem;
}
::placeholder {
    font-family: inherit;
    /* color: var(--grey-400) !important; */
    letter-spacing: var(--letterSpacing);
}
.form-alert {
    color: var(--red-dark);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
}
/* alert */

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--grey-400);
    border-radius: 50%;
    border-top-color: var(--primary-500);
    animation: spinner 0.6s linear infinite;
    margin: 0 auto;
}
.loading {
    margin: 0 auto;
}
/* title */

.title {
    text-align: center;
}

.title-underline {
    background: var(--primary-500);
    width: 7rem;
    height: 0.25rem;
    margin: 0 auto;
    margin-top: -1rem;
}
.page {
    width: var(--fluid-width);
    max-width: var(--max-width);
    margin: 0 auto;
}
.full-page {
    min-height: 100vh;
}
